define("zerorisk-frontend/mixins/controllers/merchants/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _defineProperty2, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var filterTypeMap = {
    cnpOnly: 'string',
    smartSegment: 'string',
    hasThreatAlerts: 'string',
    isRootDomainExposed: 'string',
    emvTransPerc: 'string',
    includeDisabled: 'string',
    nonPopularCmsFound: 'string',
    p2peTransactionsPerc: 'string',
    hasPciFails: 'string',
    additionalReqsByCardOrgs: 'string',
    pciPassed: 'string',
    showCorporateRecords: 'string',
    showRootMerchantsOnly: 'string',
    hasDeceptiveSite: 'string',
    hasMalwareSignal: 'string',
    hasHeuristicPattern: 'string'
  };
  var _default = Ember.Mixin.create({
    filterTypeMap: filterTypeMap,
    resetFilters: function resetFilters() {
      console.log("resetFilters");
      this.set('filters', {});
    },
    resetSearchQuery: function resetSearchQuery() {
      this.setProperties({
        searchQuery: '',
        debounce_searchQuery: ''
      });
    },
    resetQueryParams: function resetQueryParams() {
      var _this = this;
      this.queryParams.forEach(function (param) {
        if (!_this.excludedFilters.includes(param)) {
          _this.set(param, null);
        }
      });
    },
    setupFiltersFromQueryParams: function setupFiltersFromQueryParams() {
      var _this2 = this;
      if (this.queryParams && this.filters) {
        this.queryParams.any(function (param) {
          if (!_this2.excludedFilters.includes(param) && param !== 'filter') {
            if (_this2.get(param)) {
              _this2.filters[param] = _this2.get(param);
            }
          }
        });
      }
    },
    searchWithCurrentFilters: function searchWithCurrentFilters() {
      return true;
    },
    setFilter: function setFilter(filter) {
      var filterCollection = Ember.isPresent(this.filter) && Ember.isArray(this.filter) ? this.filter : [];
      var filters = [].concat((0, _toConsumableArray2.default)(filterCollection), [filter]);
      this.set('filter', filters);
    },
    handleFilterForString: function handleFilterForString(_ref) {
      var id = _ref.id,
        value = _ref.value,
        filter = _ref.filter;
      this.setFilter(filter);
      this.set(id, value);
    },
    handleFilterForArray: function handleFilterForArray(_ref2) {
      var id = _ref2.id,
        value = _ref2.value,
        filter = _ref2.filter;
      this.setFilter(filter);
      if (!(this.get(id) instanceof Array)) {
        this.set(id, [value]);
      } else {
        this.set(id, [].concat((0, _toConsumableArray2.default)(this.get(id)), [value]));
      }
    },
    removeFilterForString: function removeFilterForString(_ref3) {
      var _this$setProperties;
      var filter = _ref3.filter,
        prop = _ref3.prop;
      var newState = this.filter.without(filter);
      this.setProperties((_this$setProperties = {}, (0, _defineProperty2.default)(_this$setProperties, prop, ''), (0, _defineProperty2.default)(_this$setProperties, "filter", newState), _this$setProperties));
    },
    removeFilterForArray: function removeFilterForArray(_ref4) {
      var _this$setProperties2;
      var filter = _ref4.filter,
        prop = _ref4.prop,
        value = _ref4.value;
      var items = this.get(prop).without(value);
      var newState = this.filter.without(filter);
      this.setProperties((_this$setProperties2 = {}, (0, _defineProperty2.default)(_this$setProperties2, prop, items), (0, _defineProperty2.default)(_this$setProperties2, "filter", newState), _this$setProperties2));
    },
    actions: {
      addFilter: function addFilter(_ref5) {
        var id = _ref5.id,
          value = _ref5.value,
          filter = _ref5.filter;
        var type = this.filterTypeMap[id] || 'array';
        this["handleFilterFor".concat(Ember.String.capitalize(type))]({
          id: id,
          value: value,
          filter: filter
        });
        this.setupFiltersFromQueryParams();
      },
      removeFilter: function removeFilter(filter, _ref6) {
        var prop = _ref6.id,
          value = _ref6.value;
        var type = this.filterTypeMap[prop] || 'array';
        this["removeFilterFor".concat(Ember.String.capitalize(type))]({
          filter: filter,
          prop: prop,
          value: value
        });
        this.setupFiltersFromQueryParams();
      },
      requestSegmentCreation: function requestSegmentCreation(searchQuery) {
        if (searchQuery) {
          this.filters['searchQuery'] = searchQuery;
        }
        this.segmentManager.requestSegmentCreation(this.filters);
      },
      toggleFilters: function toggleFilters() {
        this.toggleProperty('showFilters');
      }
    }
  });
  _exports.default = _default;
});